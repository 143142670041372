import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/root.reducer';

const store = configureStore({
  reducer: {
    rootReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
