export enum PageErrorCodeEnum {
  NOT_AUTHENTICATED = '401',
  FORBIDDEN = '403',
  NOT_FOUND = '404',
  SERVER_ERROR = '500',
}

export const WAITING_LIST_API_URL = 'https://enterprise.chaka.com/api/v1/subscriptions/email';

export enum SocialLinks {
  TWITTER = 'https://x.com/ChakaStocks?t=mtyRlPLcMbwQatSxRx4wpQ&s=31',
  INSTAGRAM = 'https://www.instagram.com/chakastocks/',
}
