import { ThunkActionAPIState } from '@app-config';
import { createSlice } from '@reduxjs/toolkit';
import { signupAction } from '../actions/waiting-list.action';

interface IWaitingListState {
  signupStatus: ThunkActionAPIState;
  signupSuccess: string;
  signupError?: string;
  data?: { id: string; email: string };
}

const initialState: IWaitingListState = {
  signupError: '',
  signupStatus: 'idle',
  signupSuccess: '',
  data: undefined,
};

const slice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    resetSignupStatus(state) {
      state.signupStatus = 'idle';
      state.signupSuccess = '';
      state.signupError = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(signupAction.pending, state => {
        state.signupStatus = 'loading';
      })
      .addCase(signupAction.fulfilled, (state, action) => {
        state.signupStatus = 'completed';
        state.signupSuccess = action.payload.message;
        state.data = action.payload.data;
      })
      .addCase(signupAction.rejected, (state, action) => {
        state.signupStatus = 'failed';
        state.signupError = action?.payload?.message ?? action.error.message;
      });
  },
});

export const { resetSignupStatus } = slice.actions;
export default slice.reducer;
