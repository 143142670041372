import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { lazy } from 'react';

const LandingPage = lazy(() => import('../pages/LandingPage'));
const Page404 = lazy(() => import('../pages/error/Page404'));
const Page500 = lazy(() => import('../pages/error/Page500'));

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<LandingPage />} errorElement={<Page404 />}>
      <Route path="/error" element={<Page500 />} />
    </Route>,
  ),
);
