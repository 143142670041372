import asyncThunkWrapperUtil from '../../utils/async-thunk-wrapper.util';
import { IWaitingListSignup } from '@app-form';
import axios from 'axios';
import { WAITING_LIST_API_URL } from '../../constants';
import { WaitingListApiResponseType } from '@app-config';

export const SIGN_UP = 'waitingList:SIGN_UP';

export const signupAction = asyncThunkWrapperUtil<WaitingListApiResponseType, IWaitingListSignup>(SIGN_UP, async args => {
  const response = await axios.post(WAITING_LIST_API_URL, args);

  return response.data;
});
