import { useMemo } from 'react';
import { createTheme, responsiveFontSizes } from '@mui/material';

import componentsOverride from '../theme/overrides';
import palette from '../theme/palette';
import typography from '../theme/typography';
import shadows, { customShadows } from '../theme/shadows';
import { Shadows } from '@mui/material/styles';

export default function useAppTheme() {
  const theme = useMemo(
    () =>
      createTheme({
        palette: palette.light,
        typography,
        customShadows: customShadows.dark,
        shadows: shadows.light as Shadows,
        shape: { borderRadius: 8 },
      }),
    [],
  );

  responsiveFontSizes(theme);
  theme.components = componentsOverride(theme);

  return { theme };
}
