import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiRejectValueType } from '@app-config';

export default function asyncThunkWrapperUtil<Returned, Args>(prefix: string, handler: (args: Args, thunkAPI?: unknown) => Promise<any>) {
  return createAsyncThunk<
    Returned,
    Args,
    {
      rejectValue: ThunkApiRejectValueType;
    }
  >(prefix, async (args, thunkAPI) => {
    try {
      return await handler(args, thunkAPI);
    } catch (e: any) {
      if (e.response) return thunkAPI.rejectWithValue(e.response.data);
      return Promise.reject(e);
    }
  });
}
