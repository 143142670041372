import React, { Suspense } from 'react';
import { CssBaseline, LinearProgress, StyledEngineProvider, ThemeProvider } from '@mui/material';
import useAppTheme from './hooks/useAppTheme';
import { RouterProvider } from 'react-router-dom';
import routes from './routes';

function App() {
  const { theme } = useAppTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <Suspense fallback={<LinearProgress variant="indeterminate" />}>
          <RouterProvider router={routes} />
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
